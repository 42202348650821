import {
  Box, Card, CardActionArea, CardContent, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomCard = ({
  img, title, description, onClick,
}) => {
  const isClickable = () => onClick;

  const getCardContent = () => (
    <CardContent className="custom-card-container">
      <Box>
        <img src={img} alt="IMG" />
      </Box>
      <Box style={{ marginLeft: '20px' }}>
        <Typography className="custom-card-title">{title}</Typography>
        <Typography className="custom-card-description">{description}</Typography>
      </Box>
    </CardContent>
  );

  return (
    <>
      <Card>
        {isClickable() ? (
          <CardActionArea onClick={onClick}>
            {getCardContent()}
          </CardActionArea>
        ) : (
          <>
            {getCardContent()}
          </>
        )}
      </Card>
    </>
  );
};

CustomCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CustomCard.defaultProps = {
  onClick: undefined,
};

export default CustomCard;
