import { Box, Grid, Typography } from '@material-ui/core';
import calculatorImg from '../../assets/imgs/calculator_animated.svg';
import i18n from '../../common/i18n';

/* eslint arrow-body-style:off */
const CalculatorLoading = () => {
  return (
    <>
      <Box mt={5} />
      <Grid container>
        <Grid item xs={12} md={12}>
          <img src={calculatorImg} alt="calculando" width="100px" />
          <br />
          <Typography variant="p">
            {i18n.calculatorLoadingMessage1}
            <br />
            {i18n.calculatorLoadingMessage2}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CalculatorLoading;
