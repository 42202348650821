import {
  Box, Grid, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { cellphonechemaValidation } from '../forms/schemaValidations/schemaValidations';
import i18n from '../common/i18n';
import TextInput from './common/TextInput';
import LoadingButton from './common/LoadingButton';
import SelectedUser from './SelectedUser';

const CellphoneInput = ({
  user, goToBack, onSubmit, loading,
}) => {
  const methods = useForm({
    resolver: yupResolver(cellphonechemaValidation),
    mode: 'onChange',
  });

  return (
    <>
      <Grid container align="left">
        <Grid item xs={12} md={6}>
          <Box component="div" className="paddingVidrieras">
            <Box m={3} />
            <SelectedUser
              user={user}
              goToBack={goToBack}
            />
            <Box m={3} />
            <Typography variant="h2" className="subtitle">{i18n.cellphoneInputValidatePhone}</Typography>
            <Box m={3} />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TextInput
                  variant="standard"
                  name="cellphone"
                  label={i18n.cellphoneInputCellphoneInputLabel}
                  fullWidth
                  helperText={i18n.cellphoneInputCellphoneInputHint}
                  inputProps={{ maxLength: 12 }}
                  mandatory
                />
                <Box m={3} />
                <Typography className="input-cellphone-evaluation-hint">{i18n.cellphoneInputEvaluationHint}</Typography>
                <Box m={5} />
                <LoadingButton
                  color="primary"
                  type="submit"
                  style={{ width: '100%' }}
                  loading={loading}
                  disabled={!methods.formState.isValid}
                >
                  {i18n.cellphoneInputCTALabel}
                </LoadingButton>
              </form>
            </FormProvider>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

CellphoneInput.propTypes = {
  user: PropTypes.shape({
    nombrecompleto: PropTypes.string.isRequired,
    cuit: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  goToBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CellphoneInput;
