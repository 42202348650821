import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { isShowcaseBranch, isMobileOperatingSystem } from '../utils/functionsUtil';
import showcaseBranchImg from '../assets/imgs/Chica-sucu-credicuotas 2.png';
import i18n from '../common/i18n';

const Banner = ({ showcaseInfo }) => {
  const getBannerByType = () => {
    let bannerType = 'electro';
    showcaseInfo.products.forEach((product) => {
      if (product.sappIdProducto === 2 || product.sappIdProducto === 36) {
        bannerType = 'moto';
      }
    });
    return bannerType;
  };

  return (
    <>
      {isShowcaseBranch(showcaseInfo) ? (
        <>
          <Box component="header" className="vidrieras-sucursal">
            {isMobileOperatingSystem() ? (
              <Box>
                <Typography className="banner-branch-title">{i18n.homeShowcaseBranchMobile[0]}</Typography>
                <Typography className="banner-branch-subtitle">{i18n.homeShowcaseBranchMobile[1]}</Typography>
              </Box>
            ) : (
              <Typography className="banner-branch-title">{i18n.homeShowcaseBranch}</Typography>
            )}
            <img src={showcaseBranchImg} alt="logo" className="img-logo-vidrieras-sucursal" />
          </Box>
        </>
      ) : (
        <>
          <Box component="header" className={`vidrieras ${getBannerByType()}`}>
            <Box component="div">
              <img src={showcaseInfo.logoImageUrl} alt="logo" className="img-logo-vidrieras" />
            </Box>
          </Box>
          <Box className="vidrieras description">
            <Typography className="vidrieras description">
              <strong>
                {`${showcaseInfo.businessName} `}
              </strong>
              {showcaseInfo.description}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

Banner.propTypes = {
  showcaseInfo: PropTypes.shape({
    logoImageUrl: PropTypes.string.isRequired,
    businessName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default Banner;
