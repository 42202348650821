import { fetch } from './axiosInstance';

export const getShowcaseInformation = (showcaseId) =>
  fetch({
    url: `/onboarding/getshowcaseinfo/${showcaseId}`,
    method: 'get',
  });

export const resolveCustomers = (custId) =>
  fetch({
    url: `/onboarding/resolvecustomers/${custId}`,
    method: 'get',
  });

export const updateCustomerData = (customerData) =>
  fetch({
    url: '/onboarding/updatecustomerdata',
    method: 'post',
    data: customerData,
  });

export const checkBlacklist = (phoneNumber) =>
  fetch({
    url: '/onboarding/checkblacklist',
    method: 'post',
    data: {
      userInputValue: phoneNumber,
    },
  });

export const sendVerificationCode = (phoneNumber) =>
  fetch({
    url: '/onboarding/sendcodeshowcase',
    method: 'post',
    data: {
      userInputName: 'WT_PHONE',
      userInputValue: phoneNumber,
      userCellnumber: phoneNumber,
    },
  });

export const savePreSimulationLoan = (customerData) =>
  fetch({
    url: '/onboarding/savepresimulationloan',
    method: 'post',
    data: customerData,
  });

export const verifyCode = (verificationData) =>
  fetch({
    url: '/onboarding/verifycode',
    method: 'post',
    data: verificationData,
  });

export const startWorkflow = () =>
  fetch({
    url: '/onboarding/start',
    method: 'get',
  });

export const getOnboardingOffer = (hash) =>
  fetch({
    url: `/onboarding/offer/${hash}`,
    method: 'get',
  });

export const sendOfferToCustomer = (showcaseId, hash) =>
  fetch({
    url: `/onboarding/showcase/sendoffer/${showcaseId}/${hash}`,
    method: 'post',
  });

export const getCommerceListByHeadOffice = (headOffice) =>
  fetch({
    url: `/onboarding/getCommerceListByHeadOffice/${headOffice}?onlyEnabled=true`,
    method: 'get',
  });

export const getLoanRequest = (hash) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}`,
    method: 'get',
  });

export const getInstallments = (hash, requestedAmount, needDetail) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments/${requestedAmount}?needInstallmentsDetail=${needDetail}`,
    method: 'get',
  });

export const getInstallmentsWithOriginalLine = (hash, requestedAmount, originalLineId) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments/${requestedAmount}/originalLine/${originalLineId}`,
    method: 'get',
  });

export const getHashLoanByMinifyIdWithUrlParams = (hash) =>
  fetch({
    url: `/onboarding/redirectwithurlparams/${hash}`,
    method: 'get',
  });

export const setInstallment = (hash, installment, requestedAmount) =>
  fetch({
    url: `/onboarding/loanRequest/${hash}/installments`,
    method: 'put',
    data: {
      installmentAmountSelected: installment.amount,
      installmentsNumberSelected: installment.installments,
      idFinanceLine: installment.idLinea,
      requestedAmount,
    },
  });

export const selfManagement = (hash) =>
  fetch({
    url: `/onboarding/${hash}/generateSelfManagedLink`,
    method: 'post',
  });
