import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import walkingPersonImg from '../assets/imgs/loanOffer_walking-person.svg';
import loanOfferPayingImg from '../assets/imgs/loanOffer_paying.svg';
import dniImg from '../assets/imgs/loanOffer_dni.svg';
import motoImg from '../assets/imgs/loanOffer_moto.svg';
import signImg from '../assets/imgs/loanOffer_firma.svg';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

/* eslint arrow-body-style:off */
const NextSteps = ({ isMoto, installments }) => {
  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <Grid container>
            <Grid item xs={12} md={12}>
              {
                isMoto
                  ? (<img src={walkingPersonImg} width="65px" alt="Icono de persona caminando" />)
                  : (<img src={loanOfferPayingImg} width="65px" alt="Icono de pago" />)
              }
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>
                {
                  isMoto
                    ? i18n.nextStepsStep1
                    : fillMessageWith(i18n.nextStepsElectroStep1, installments)
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <img src={dniImg} width="65px" alt="Icono de DNI" />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>
                {isMoto ? i18n.nextStepsStep2 : i18n.nextStepsElectroStep2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {isMoto && (
        <Grid item xs={12} md={3}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <img src={motoImg} width="65px" alt="Icono de producto" />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>{i18n.nextStepsStep3}</Typography>
            </Grid>
          </Grid>
        </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <img src={signImg} width="65px" alt="Icono de firma de documento" />
            </Grid>
            <Grid item xs={12} md={12}>
              {
                isMoto
                  ? <Typography>{ i18n.nextStepsStep4 }</Typography>
                  : (
                    <Typography>
                      <Typography>{ i18n.nextStepsElectroStep3_1 }</Typography>
                      <Typography>{ i18n.nextStepsElectroStep3_2 }</Typography>
                    </Typography>
                  )
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

NextSteps.propTypes = {
  isMoto: PropTypes.bool,
  installments: PropTypes.string,
};

NextSteps.defaultProps = {
  isMoto: true,
  installments: '',
};

export default NextSteps;
