import PropTypes from 'prop-types';
import {
  Card, CardContent, Grid, makeStyles, Typography, CardActionArea,
} from '@material-ui/core';
import timeMoney from '../assets/imgs/timeMoney.svg';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import i18n from '../common/i18n';

const useStyles = makeStyles(() => ({
  /* Beneficio primer cuota */
  row: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  pink: {
    color: '#F2025D',
  },
  message: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 10,
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  installmentMessage: {
    fontSize: '0.8rem',
  },
  installment: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  link: {
    color: '#4a4a4a',
    textDecoration: 'underline',
    fontSize: '0.7rem',
  },
}));

/* eslint arrow-body-style:off */
const BenefitFirstIntallment = ({ selectedInstallment }) => {
  const classes = useStyles();

  return (
    <>
      <Card>
        <CardActionArea disableRipple>
          <CardContent>
            <Grid container>

              <Grid item className={classes.row}>
                <img
                  src={timeMoney}
                  alt="Imagen de reloj y dinero"
                  width="60px"
                />
                <Typography className={classes.message}>
                  <span className={classes.pink}>
                    {selectedInstallment.firstInstallmentDiscountPercentage * 100}
                    %
                  </span>
                  {' de descuento en tu '}
                  <span className={classes.pink}>primera cuota</span>
                </Typography>
              </Grid>

              <Grid item className={classes.row} style={{ marginTop: '18px' }}>
                <Typography className={classes.installmentMessage}>
                  <b>Si tomás el préstamo hoy</b>
                  {' y abonás la primer cuota en término, '}
                  <span className={classes.installment}>
                    {'pagás: '}
                    <span className={classes.pink}>
                      {
                        moneyFormatterWithoutDecimals.format(selectedInstallment.firstInstallmentAmountWithDiscount)
                      }
                    </span>
                  </span>
                  <br />
                  <a
                    className={classes.link}
                    href="https://rddrdge2q9.preview-postedstuff.com/V2-FeTN-5tQ6-R1zI-YRt0/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i18n.benefitFirstIntallmentTerms}
                  </a>
                </Typography>
              </Grid>

            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

BenefitFirstIntallment.propTypes = {
  selectedInstallment: PropTypes.shape({
    firstInstallmentAmountWithDiscount: PropTypes.number,
    firstInstallmentDiscountPercentage: PropTypes.number,
  }).isRequired,
};

export default BenefitFirstIntallment;
