import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import i18n from '../common/i18n';
import locationImg from '../assets/imgs/maps.svg';

/* eslint arrow-body-style:off */
const Branches = ({ commerceList }) => {
  return (
    <>
      {commerceList && (
        <>
          <Grid container align="left" justify="flex-start">
            <Grid item xs={12} md={12}>
              <Typography variant="h3">{i18n.branchesTitle}</Typography>
            </Grid>
          </Grid>
          <Box mt={3} />
          <Grid container align="left">
            {commerceList.map((commerce) => (
              <Grid item xs={12} md={12} key={commerce.id}>
                <Box component="div" className="branch-location">
                  <img src={locationImg} className="branch-icon" alt="Icono de ubicación en mapa" />
                  <Typography className="capitalize">
                    {`${commerce.street?.toLowerCase()} ${commerce.number}, ${commerce.location?.toLowerCase()}, 
                    ${commerce.state?.toLowerCase()}`}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

Branches.propTypes = {
  commerceList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Branches;
