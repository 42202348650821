import { Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

const Toast = ({
  messageOnSuccess, messageOnError, open, onClose,
}) => {
  const severity = messageOnSuccess ? 'success' : 'error';
  const message = messageOnSuccess || messageOnError;
  const handleOnClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };
  return (
    <>
      <Snackbar open={open}>
        <Alert onClose={handleOnClose} severity={severity}>
          <Typography>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

Toast.propTypes = {
  messageOnSuccess: PropTypes.string,
  messageOnError: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
Toast.defaultProps = {
  messageOnSuccess: undefined,
  messageOnError: undefined,
};
export default Toast;
