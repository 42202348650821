import {
  Box, Grid, List, ListItem, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';
import CardUserConfirmation from './CardUserConfirmation';

/* eslint arrow-body-style:off */
const IdentityResolver = ({ users, goToNext, goToBack }) => {
  return (
    <>
      <Box component="div" className="paddingVidrieras">
        <Grid container justify="left">
          <Grid item xs={12}>
            <Box m={3} />
            <Typography variant="h2" className="identity-title">{i18n.identityResolverTitle}</Typography>
          </Grid>
        </Grid>
        <Grid container justify="left">
          <Grid item xs={12} sm={12} md={6}>
            <List>
              {users.map((user) => (
                <ListItem key={user.cuit}>
                  <CardUserConfirmation
                    name={user.nombrecompleto}
                    cuit={fillMessageWith(i18n.identityResolverCUIT, [user.cuit])}
                    onClick={() => { goToNext(user); }}
                  />
                </ListItem>
              ))}
              <ListItem>
                <CardUserConfirmation name={i18n.identityResolverNone} onClick={goToBack} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

IdentityResolver.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    nombrecompleto: PropTypes.string.isRequired,
    cuit: PropTypes.string.isRequired,
  })).isRequired,
  goToNext: PropTypes.func.isRequired,
  goToBack: PropTypes.func.isRequired,
};

export default IdentityResolver;
