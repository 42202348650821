export const moneyFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 2,
});

export const moneyFormatterWithoutDecimals = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
});

export const dateFormatter = (date) => {
  const options = {
    timeZone: 'UTC',
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};

export const dateTimeFormatter = (date) => {
  const options = {
    timeZone: 'America/Argentina/Buenos_Aires',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};

export const amountFormatter = (value) => { // 1234 -> 12.34
  let response = value;
  const lengthResponse = response.length;
  if (lengthResponse < 3) {
    response.padStart(3, '0');
  }
  response = `${value.substring(0, lengthResponse - 2)}.${value.substring(lengthResponse - 2)}`;
  return response;
};
