import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { fillMessageWith } from '../utils/functionsUtil';
import i18n from '../common/i18n';
import imgDni from '../assets/imgs/dni2.svg';
import imgPhone from '../assets/imgs/phone.svg';

/* eslint arrow-body-style:off */
const HowTo = ({ showcaseBusinessName, showcaseProducts }) => {
  const [isElectro, setIsElectro] = useState();

  const init = async () => {
    const pM = showcaseProducts.find((product) => product.sappIdProducto === 2 || product.sappIdProducto === 36);
    setIsElectro(pM === undefined);
  };

  useEffect(() => init(), []);

  return (
    <>
      <Box m={3} />
      <Box component="div" className="paddingVidrieras">
        <Typography variant="h2" className="vidrieras">{i18n.howToTitle}</Typography>
        <Typography className="vidrieras">
          {isElectro
            ? (
              <>
                {i18n.howToDescriptionElectro}
                <b>{fillMessageWith(i18n.howToDescriptionElectro2, showcaseBusinessName)}</b>
                {i18n.howToDescriptionElectro3}
              </>
            )
            : (
              <>
                {i18n.howToDescription}
                <b>{` ${showcaseBusinessName}.`}</b>
                <br />
                {i18n.howToOnlyNeeds}
              </>
            )}
        </Typography>
        <Box m={3} />
        <Grid container>
          <Grid item xs={6} md={6}>
            <Box component="div" display="flex" justifyContent="flex-start" alignItems="center">
              <img src={imgDni} alt="dni" width="60px" />
              <Box m={1} />
              <Typography className="font-size-18 margin-10-t">{i18n.howToDNI}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box component="div" display="flex" justifyContent="flex-start" alignItems="center">
              <img src={imgPhone} alt="dni" width="30px" />
              <Box m={1} />
              <Typography className="font-size-18 margin-10-t">{i18n.howToPhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

HowTo.propTypes = {
  showcaseBusinessName: PropTypes.string.isRequired,
  showcaseProducts: PropTypes.arrayOf.isRequired,
};

export default HowTo;
