import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import {
  Grid, makeStyles,
} from '@material-ui/core';
import i18n from '../common/i18n';
import logo from '../assets/imgs/logo.svg';
import '../index.css';
import { ENVIRONMENT, getEnv } from '../api/env';

const useStyles = makeStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '0px 0px 10px #d6d6d6',
    position: 'relative',
    borderBottom: '1px solid #D6D6D6',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
});
const NavBar = () => {
  const classes = useStyles();

  const location = useLocation().pathname;
  useEffect(() => {
    if (getEnv(ENVIRONMENT) === 'PRD') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          path: location,
        },
      });
      ReactGA.send({ hitType: 'pageview', page: location });
    }
  }, [location]);

  return (
    <Grid container className={classes.header} alignContent="space-between" alignItems="center">
      <Grid item xs={3}>
        <img
          src={logo}
          className="logo-cc"
          alt={i18n.altBrand}
          width="200px"
          style={{ float: 'left', marginLeft: '25px' }}
        />
      </Grid>
    </Grid>
  );
};

export default NavBar;
