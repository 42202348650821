import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import Loading from '../components/common/Loading';
import NavBar from '../components/NavBar';
import { useUserData } from '../contexts/userDataContext';
import {
  resolveCustomers, updateCustomerData, checkBlacklist, sendVerificationCode,
  savePreSimulationLoan, verifyCode, startWorkflow,
} from '../api/showcaseService';
import IdentityResolver from '../components/IdentityResolver';
import StartOnboarding from '../components/StartOnboarding';
import CellphoneInput from '../components/CellphoneInput';
import VerificationCode from '../components/VerificationCode';
import Toast from '../components/common/Toast';
import i18n from '../common/i18n';
import { routes } from '../constants/routes';
import CalculatorLoading from '../components/common/CalculatorLoading';
import MaintenanceMessage from '../components/MaintenanceMessage';

const Onboarding = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [calculatingOffer, setCalculatingOffer] = useState(false);
  const { userData, setUserData } = useUserData();
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [showInput, setShowInput] = useState(false);
  const [showTaxIdSelector, setShowTaxIdSelector] = useState(false);
  const [showCellphoneInput, setShowCellphoneInput] = useState(false);
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showMaintenanceMessage, setShowMaintenanceMessage] = useState(undefined);

  const history = useHistory();

  const queryParamList = new URLSearchParams(useLocation().search);

  const showResponseErrorOrDefault = (error) => {
    if (error.response && error.response.data && error.response.data.cause) {
      setErrorMessage(error.response.data.cause);
    } else {
      setErrorMessage(i18n.genericErrorMessage);
    }
  };

  const selectUser = async (user) => {
    try {
      setShowInput(false);
      setShowTaxIdSelector(false);
      setShowCellphoneInput(true);
      setSelectedUser(user);

      const showcaseId = window.location.hostname.split('.')[0];

      const customerData = {
        customerTaxId: user.cuit,
        fullname: user.nombrecompleto,
        sex: user.sexo,
        birthdate: user.fechanacimiento,
        destinationCode: 'OTHER',
        showCaseCommerce: showcaseId,
        showcaseSellerIdentifier: queryParamList.get('identifier'),
      };

      await updateCustomerData(customerData);
    } catch (error) {
      setErrorMessage(i18n.genericErrorMessage);
    }
  };

  const goToBack = () => {
    setSelectedUser(undefined);
    setPhoneNumber(undefined);
    setShowInput(true);
    setShowTaxIdSelector(false);
    setShowCellphoneInput(false);
    setShowVerificationCodeInput(false);
  };

  const init = async (data) => {
    try {
      setInitLoading(true);
      if (data && data.dni) {
        const res = await resolveCustomers(data.dni);
        const users = res.data;
        setUserData({ ...data, users });
        if (users && users.length > 1) {
          setShowTaxIdSelector(true);
        } else {
          selectUser(users[0]);
        }
      } else {
        setShowInput(true);
      }
    } catch (error) {
      setErrorMessage(i18n.genericErrorMessage);
    } finally {
      setInitLoading(false);
    }
  };

  const onInputIdentification = (data) => {
    setLoading(true);
    setShowInput(false);
    setUserData(data);
    init(data);
    setLoading(false);
  };

  const sendSmsCode = async (cellphoneNumber) => {
    try {
      await sendVerificationCode(cellphoneNumber);
    } catch (error) {
      setErrorMessage(i18n.cellphoneInputErrorMessage);
    }
  };

  const sendValidationCode = async ({ cellphone }) => {
    try {
      setLoading(true);
      const cellphoneNumber = Number(cellphone);
      setPhoneNumber(cellphoneNumber);

      await checkBlacklist(cellphoneNumber);

      sendSmsCode(cellphoneNumber);

      const showcaseId = window.location.hostname.split('.')[0];

      const customerData = {
        userInputValue: cellphoneNumber,
        userInputName: selectedUser.nombrecompleto,
        userId: selectedUser.dni,
        userDestinationLoan: 'OTHER',
        userType: `showcase_${showcaseId}`,
      };

      await savePreSimulationLoan(customerData);

      setShowCellphoneInput(false);
      setShowVerificationCodeInput(true);
    } catch (error) {
      showResponseErrorOrDefault(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangePhone = () => {
    setShowCellphoneInput(true);
    setShowVerificationCodeInput(false);
  };

  const onResend = () => {
    sendValidationCode({ cellphone: phoneNumber });
  };

  const initWorkflow = async () => {
    try {
      setCalculatingOffer(true);
      const res = await startWorkflow();
      history.push(`${routes.offer}/${res.data.hashKey}`);
    } catch (error) {
      if (error?.response?.data?.errorCode === 'SERVICE_UNAVAILABLE_FOR_MAINTENANCE') {
        setShowMaintenanceMessage(true);
      } else {
        setErrorMessage(i18n.startOnboardingStartOfferError);
      }
    } finally {
      setCalculatingOffer(false);
    }
  };

  const validateCode = async ({ code }) => {
    try {
      const data = {
        userInputName: 'WT_PHONE',
        userInputVerificationCode: code,
        userInputValue: phoneNumber,
        userCellnumber: phoneNumber,
      };
      const res = await verifyCode(data);
      if (res.data.result) {
        initWorkflow();
      } else {
        setErrorMessage(i18n.verificationCodeInvalidCodeErrorMessage);
      }
    } catch (error) {
      showResponseErrorOrDefault(error);
    }
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => init(userData), []);

  return (
    <>
      <NavBar />
      {!initLoading && !calculatingOffer && !showMaintenanceMessage && (
        <>
          {showInput && (
            <Grid container align="left">
              <Grid item xs={12} md={6}>
                <StartOnboarding
                  onSubmit={onInputIdentification}
                  loading={loading}
                />
              </Grid>
            </Grid>
          )}
          {showTaxIdSelector && (
            <IdentityResolver
              users={userData.users}
              goToNext={selectUser}
              goToBack={goToBack}
            />
          )}
          {showCellphoneInput && (
            <CellphoneInput
              user={selectedUser}
              goToBack={goToBack}
              onSubmit={sendValidationCode}
              loading={loading}
            />
          )}
          {showVerificationCodeInput && (
            <VerificationCode
              user={selectedUser}
              goToBack={goToBack}
              onSubmit={validateCode}
              loading={loading}
              phoneNumber={phoneNumber}
              onChangePhone={onChangePhone}
              onResend={onResend}
            />
          )}
        </>
      )}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={dismissMessage}
        />
      )}
      {initLoading && (
        <Loading />
      )}
      {calculatingOffer && (
        <CalculatorLoading />
      )}
      {showMaintenanceMessage && (
        <MaintenanceMessage />
      )}
    </>
  );
};

export default Onboarding;
