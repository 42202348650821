/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
const i18n = {
  // home
  homeGetShowcaseInfoErrorMessage: 'No pudimos encontrar a un comercio asociado',
  homeShowcaseDisable: 'En este momento la página no se encuentra disponible',
  homeShowcaseBranch: '¡Pedí tu préstamo online y retiralo por caja!',
  homeShowcaseBranchMobile: [
    '¡Calificate ahora!',
    'Pedí tu préstamo online y retiralo por caja.',
  ],

  // howTo
  howToTitle: '¿Cómo hago?',
  howToDescription: 'Obtené la calificación para el préstamo. Luego acercarte a la sucursal de',
  howToDescriptionElectro: 'Ingresá tu DNI y obtené la calificación para financiarte la compra con nuestro préstamo. Luego',
  howToDescriptionElectro2: ' comunicate con un vendedor de ${0} ',
  howToDescriptionElectro3: 'para finalizar la compra y coordinar la entrega del producto',
  howToOnlyNeeds: 'Solo necesitas:',
  howToDNI: 'DNI',
  howToPhone: 'Celular',

  // startOnboarding
  startOnboardingTitle: 'Comencemos!',
  startOnboardingSubtitle: 'Ingresá tu DNI',
  startOnboardingInputDNIHelperText: 'Sin espacios ni puntos, máximo 9 dígitos',
  startOnboardingButtonInitRequest: 'Iniciar mi solicitud',
  startOnboardingStartOfferError: 'Ocurrió un error inesperado mientras te evaluamos',

  // startOnboardingBranch
  startOnboardingBranchTitle: 'Ingresá tu DNI y calificate',
  startOnboardingBranchSubtitle: 'Podés completar tu préstamo vos mismo o pedir ayuda al vendedor. Al finalizar pasás por caja a retirar tu efectivo',

  // footer
  footerDescription1: 'Somos una compañía financiera 100% online que ofrece préstamos personales online a individuos. Lideramos el segmento de préstamos personales, con más de 8 años en el mercado financiero.',
  footerDescription2: 'CREDICUOTAS CONSUMO SA con CUIT 30-71091158-0 con domicilio en Arenales 707, piso 7 (CABA) es la razón social de la marca Credicuotas.',

  // identityResolver
  identityResolverTitle: 'Confirmanos tu nombre',
  identityResolverCUIT: 'CUIT: ${0}',
  identityResolverNone: 'No soy esa persona',

  // cellphoneInput
  cellphoneInputDNIValidated: 'DNI validado',
  cellphoneInputChangeUser: 'No soy yo',
  cellphoneInputValidatePhone: 'Ahora necesitamos validar tu celular',
  cellphoneInputCellphoneInputLabel: 'Ingresá el celular (Con código de área)',
  cellphoneInputCellphoneInputHint: 'Cod. Área sin ceros adelante y sin el 15. Ej 1154673007',
  cellphoneInputEvaluationHint: 'Para calificarte, necesitamos validar tu celular mediante un código vía SMS.',
  cellphoneInputCTALabel: 'Enviar código',
  cellphoneInputErrorMessage: 'Upss, hubo un problema, por favor reintente enviar el sms',

  // verificationCode
  verificationCodeSubtitle: 'El código fue enviado al celular',
  verificationCodeInputCodeLabel: 'Código de validación',
  verificationCodeInputHint: 'Si no recibiste el código vas a poder reenviarlo en',
  verificationCodeResend: 'Reenviar',
  verificationCodeCTALabel: 'Validar',
  verificationCodeTerms1: 'Acepto los ',
  verificationCodeTermsAndConditions: 'Terminos y Condiciones',
  verificationCodeTerms2: ', y la ',
  verificationCodePrivacyPolicy: 'Política de Privacidad',
  verificationCodeTerms3: ' y gestionar mi Atención al Cliente vía ',
  verificationCodeWhatsapp: 'Whatsapp ',
  verificationCodeTerms4: ' para Asistencia de Ventas, Atención al Cliente y Cobranzas',
  verificationCodeInvalidCodeErrorMessage: 'El código ingresado no es valido',
  verificationCodeEvaluationErrorMessage: 'Ocurrió un error inesperado mientras te evaluamos',

  // offer
  offerApprovedRequestTitle: '¡Felicitaciones!',
  offerApprovedRequestOfferMessage: 'Tenes disponible hasta ',
  offerElectroApprovedRequestOfferMessage: 'Te prestamos ',
  offerElectroApprovedRequestOfferMessage2: 'para tu compra',
  offerApprovedRequestInstallmentMessage1: 'En hasta',
  offerApprovedRequestInstallmentMessage2: 'cuotas de',
  offerElectroApprovedRequestInstallmentMessage1: 'Comunicate con un vendedor de ${0}. ',
  offerElectroApprovedRequestInstallmentMessage2: 'Podrás elegir terminar la compra de forma online desde tu casa o presencial en la sucursal',
  offerApprovedRequestReminderMessage: 'Te enviamos un SMS con la oferta para que no la olvides!',
  offerRejectedRequestMessage: ', a partir del análisis de tu perfil crediticio no podemos brindarte una oferta en este momento.',
  offerRejectedRequestMessageDescription: [
    'Por favor comunicate',
    'con el vendedor del comercio',
    'para revisar tu caso',
  ],
  offerLoadError: 'No podemos encontrarte una oferta para tu caso',
  offerBranchDescription: [
    'Tenés disponible',
    'para llevarte por caja en efectivo.',
  ],
  offerBranchDeskTitle: 'Seguir por sucursal',
  offerBranchDeskDescription: 'Acercate a un vendedor para terminar tu préstamos. Si sacaste un turno por favor esperá a ser atendido. Sino acercate a sacar un turno para ser atendido',
  offerBranchSelfieTitle: 'Hacerlo yo mismo',
  offerBranchSelfieDescription: 'Continuá con la carga de tus documentos y datos personales vos mismo.',
  offerBranchSelfmanagedError: 'Ocurrió un error al intentar continuar con la solicitud.',

  // offerSelection
  offerRejectedLoanMessage: ', la solicitud seleccionada no cumple los riquisitos para continuar.',
  offerSelectionLoadError: 'Ocurrió un error inesperado con la oferta',

  // loanInstallments
  loanInstallmentsTitle: '¡Buenas noticias!',
  loanInstallmentsSubTitle: 'Tenés aprobado un monto de',
  loanInstallmentsContinue: 'Continuar',

  // cardInstallments
  cardInstallmentsPlan: '${0} cuotas de ',
  cardInstallmentsInterests: 'Interés: ',
  cardInstallmentsTax: 'IVA: ',

  // benefitFirstIntallment
  benefitFirstIntallmentTerms: 'Ver Términos y Condiciones',

  // branches
  branchesTitle: 'Mirá las sucursales del comercio',

  // next steps
  nextStepsStep1: 'Ahora acercate a la sucursal más cercana del comercio.',
  nextStepsStep2: 'Llevá tu DNI y comentale al vendedor que tenés esta oferta con Credicuotas (Te enviamos un SMS con la oferta para que no la olvides!)',
  nextStepsStep3: 'Elegí el producto que querés.',
  nextStepsStep4: 'Firmá la solicitud ¡y listo!',
  nextStepsElectroStep1: 'Elegí en cuántas cuotas querés pagar. Tenés hasta ${0} cuotas.',
  nextStepsElectroStep2: 'Te pediremos una foto de tu DNI y una selfie',
  nextStepsElectroStep3_1: 'Firmá la solicitud y listo.',
  nextStepsElectroStep3_2: 'Recibirás el producto en tu casa o lo pasas a retirar por la sucursal.',

  // generic error message
  genericErrorMessage: 'Ocurrió un error',

  // calculator loading
  calculatorLoadingMessage1: 'Estamos haciendo un cálculo personalizado',
  calculatorLoadingMessage2: 'para encontrarte la mejor cuota',

  // schemaValidations
  dniSchemaValidationDNIRequired: 'El DNI es requerido',
  dniSchemaValidationDNILength: 'El DNI debe ser un número entre 6 y 9 dígitos',
  verificationCodeSchameValidationCodeRequired: 'El Código es requerido',
  verificationCodeSchameValidationCodeNumber4Digits: 'El Código debe ser un número de 4 dígitos',

  maintenanceMessage: 'En estos momentos estamos realizando tareas de mantenimiento.',
  maintenanceMessageTryLater: 'Por favor volvé a intentar en una hora.',

  // loading svg
  loadingSVG1: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG2: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG3: 'M55.2 46.9c-.4-.8-.9-1.4-1.6-1.9-.7-.5-1.5-.9-2.3-1.2-.9-.3-1.8-.5-2.7-.6l-3.8-.5c-.9-.1-1.6-.3-2-.5-.4-.2-.7-.5-.7-1s.2-.9.7-1.2c.5-.3 1.4-.4 2.6-.4 1.1 0 2.1.1 3 .3.9.2 1.6.4 2.3.6.6.2 1.1.4 1.5.6.4.2.6.4.7.5.1 0 .2-.1.4-.3.2-.1.4-.3.7-.6l.6-.9c.2-.3.3-.7.3-1.2 0-.6-.2-1.1-.5-1.5-.3-.4-.7-.7-1.3-1-.9-.5-2-.8-3.3-1-.4-.1-.8-.1-1.3-.2V34c0-.9-.8-1.7-1.7-1.7h-3c-.9 0-1.7.8-1.7 1.7v1c-.5.1-.9.2-1.3.3-1.3.4-2.3.9-3.1 1.5-.8.7-1.4 1.4-1.8 2.3-.4.9-.6 1.8-.6 2.9 0 1.2.2 2.2.7 3.1.4.8 1 1.5 1.8 2s1.7.9 2.7 1.2c1 .3 2 .5 3.1.6l2.2.2c1 .1 1.8.3 2.3.5.5.2.7.6.7 1.2 0 .5-.3.9-.9 1.1-.6.2-1.5.3-2.6.3-1 0-2-.1-2.9-.2-.9-.1-1.7-.3-2.4-.5s-1.3-.4-1.8-.7c-.5-.2-.9-.4-1.1-.6l-2.4 4.3c.2.2.6.4 1.2.7.6.3 1.3.6 2.2.9.9.3 2 .5 3.3.8.2 0 .4.1.7.1v.9c0 .9.8 1.7 1.7 1.7h3c.9 0 1.7-.8 1.7-1.7V57c1.9-.3 3.5-.9 4.7-1.8 1.8-1.3 2.7-3.1 2.7-5.5 0-1.1-.3-2.1-.7-2.8z',
};

export default i18n;
