import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NavBar from '../components/NavBar';
import { getLoanRequest, getInstallments, getInstallmentsWithOriginalLine } from '../api/showcaseService';
import RejectedLoan from '../components/RejectedLoan';
import LoanInstallments from '../components/LoanInstallments';
import Loading from '../components/common/Loading';
import Toast from '../components/common/Toast';
import i18n from '../common/i18n';

const OfferSelection = () => {
  const { hash } = useParams();
  const [initLoading, setInitLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState(undefined);
  const [lrProductAttributes, setLrProductAttributes] = useState(undefined);
  const [installmentsData, setInstallmentsData] = useState(undefined);
  const [installmentsDataOriginalLine, setInstallmentsDataOriginalLine] = useState(undefined);
  const [hasBenefitFirstIntallment, setHasBenefitFirstIntallment] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const init = async () => {
    try {
      setInitLoading(true);
      const resLoanRequest = await getLoanRequest(hash);
      setLoanRequest(resLoanRequest.data);
      setLrProductAttributes(resLoanRequest.data.productToPay?.product?.productAttributes);

      const productAmount = resLoanRequest.data.productToPay?.product?.productAttributes?.productAmount;
      if (productAmount) {
        const resInstallments = await getInstallments(hash, productAmount, true);
        const sortedInstallments = resInstallments.data.sort((a, b) => (b.number - a.number));
        setInstallmentsData(sortedInstallments);

        setHasBenefitFirstIntallment(sortedInstallments[0].firstInstallmentDiscountPercentage > 0);

        const resInstallmentsOriginalLine = await getInstallmentsWithOriginalLine(hash, productAmount,
          sortedInstallments[0].idLinea);
        const sortedInstallmentsOriginalLine = resInstallmentsOriginalLine.data.sort((a, b) => (b.number - a.number));
        setInstallmentsDataOriginalLine(sortedInstallmentsOriginalLine);
      }
    } catch (error) {
      setErrorMessage(i18n.offerSelectionLoadError);
    } finally {
      setInitLoading(false);
    }
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => init(), []);

  return (
    <>
      <NavBar />
      {/* SOLICITUD RECHAZADA */}
      {!initLoading && loanRequest && !loanRequest.isApproved && !installmentsData && (
        <RejectedLoan loanRequest={loanRequest} />
      )}
      {/* SOLICITUD APROBADA */}
      {!initLoading && loanRequest && loanRequest.isApproved && installmentsData && (
        <LoanInstallments
          businessName={lrProductAttributes.showcaseName?.toUpperCase()}
          requestedAmount={lrProductAttributes.productAmount}
          installmentsData={installmentsData}
          installmentsDataOriginalLine={installmentsDataOriginalLine}
          hasBenefitFirstIntallment={hasBenefitFirstIntallment}
        />
      )}
      {initLoading && (
        <Loading />
      )}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={dismissMessage}
        />
      )}
    </>
  );
};

export default OfferSelection;
