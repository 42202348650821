import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@material-ui/core';
import { dniSchemaValidation } from '../forms/schemaValidations/schemaValidations';
import i18n from '../common/i18n';
import TextInput from './common/TextInput';
import LoadingButton from './common/LoadingButton';

const StartOnboardingBranch = ({ onSubmit, loading }) => {
  const methods = useForm({
    resolver: yupResolver(dniSchemaValidation),
    mode: 'onChange',
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box component="div" className="vidrieras onboardingdni">
                <Box component="div" className="paddingVidrieras">
                  <Box m={3} />
                  <Typography variant="h2" className="vidrieras">{i18n.startOnboardingBranchTitle}</Typography>
                  <Typography variant="h5" className="vidrieras">{i18n.startOnboardingBranchSubtitle}</Typography>
                  <Box m={3} />
                  <TextInput
                    variant="standard"
                    name="dni"
                    label={i18n.startOnboardingSubtitle}
                    fullWidth
                    helperText={i18n.startOnboardingInputDNIHelperText}
                    inputProps={{ maxLength: 9 }}
                    mandatory
                  />
                  <Box m={5} />
                  <Box>
                    <LoadingButton
                      color="primary"
                      type="submit"
                      style={{ width: '100%' }}
                      loading={loading}
                      disabled={!methods.formState.isValid}
                    >
                      {i18n.startOnboardingButtonInitRequest}
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
};

StartOnboardingBranch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default StartOnboardingBranch;
