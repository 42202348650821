import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Button, Checkbox, Grid, Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { FormProvider, useForm } from 'react-hook-form';
import i18n from '../common/i18n';
import { verificationCodeSchemaValidation } from '../forms/schemaValidations/schemaValidations';
import LoadingButton from './common/LoadingButton';
import TextInput from './common/TextInput';
import { ORIGINATION_TERMS_URL, ORIGINATION_POLICY_URL, getEnv } from '../api/env';
import SelectedUser from './SelectedUser';
import smallWtp from '../assets/imgs/small-wtp.png';

const VerificationCode = ({
  user, loading, goToBack, onSubmit, phoneNumber, onChangePhone, onResend,
}) => {
  const methods = useForm({
    resolver: yupResolver(verificationCodeSchemaValidation),
    mode: 'onChange',
  });

  const timeLimit = 60;
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const [smsCode] = useState(undefined);
  const [checked, setChecked] = useState(false);

  const resendCode = () => {
    setTimeLeft(timeLimit);
    onResend();
  };

  useEffect(() => {
    // Set to "true" when component is mounted
    let mounted = true;
    setTimeout(() => {
      // Check if the component is still mounted
      if (mounted) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
    // Set to "false" when the component is unmounted
    return () => {
      mounted = false;
    };
  }, [timeLeft]);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Grid container align="left">
        <Grid item xs={12} md={6}>
          <Box component="div" className="paddingVidrieras">
            <Box m={3} />
            <SelectedUser
              user={user}
              goToBack={goToBack}
            />
            <Box m={3} />
            <Typography variant="h2" className="subtitle">{i18n.cellphoneInputValidatePhone}</Typography>
            <Box m={2} />
            <Typography className="verification-code-cellphone">
              {`${i18n.verificationCodeSubtitle} ${phoneNumber}`}
              <Button onClick={onChangePhone}>
                <Edit fontSize="small" />
              </Button>
            </Typography>
            <Box m={3} />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TextInput
                  variant="standard"
                  name="code"
                  label={i18n.verificationCodeInputCodeLabel}
                  value={smsCode}
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  mandatory
                />
                <Box m={2} />
                {timeLeft >= 1 ? (
                  <Typography className="count-down">{`${i18n.verificationCodeInputHint} ${timeLeft}`}</Typography>
                ) : (
                  <Button onClick={resendCode}>
                    {i18n.verificationCodeResend}
                  </Button>
                )}
                <Box m={3} />
                <Typography className="terms-conditions">
                  <Checkbox
                    className="terms-check"
                    name="terms"
                    onChange={handleChange}
                  />
                  <Box component="span">
                    {i18n.verificationCodeTerms1}
                    <a
                      className="terms-conditions-link"
                      href={getEnv(ORIGINATION_TERMS_URL)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.verificationCodeTermsAndConditions}
                    </a>
                    {i18n.verificationCodeTerms2}
                    <a
                      className="terms-conditions-link"
                      href={getEnv(ORIGINATION_POLICY_URL)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.verificationCodePrivacyPolicy}
                    </a>
                    {i18n.verificationCodeTerms3}
                    <b>{i18n.verificationCodeWhatsapp}</b>
                    <img src={smallWtp} alt="dni" />
                    {i18n.verificationCodeTerms4}
                  </Box>
                </Typography>
                <Box m={5} />
                <LoadingButton
                  color="primary"
                  type="submit"
                  style={{ width: '100%' }}
                  loading={loading}
                  disabled={!methods.formState.isValid || !checked}
                >
                  {i18n.verificationCodeCTALabel}
                </LoadingButton>
              </form>
            </FormProvider>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

VerificationCode.propTypes = {
  user: PropTypes.shape({
    nombrecompleto: PropTypes.string.isRequired,
    cuit: PropTypes.string.isRequired,
  }).isRequired,
  phoneNumber: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  goToBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
};

export default VerificationCode;
