import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Box, Grid, Typography,
} from '@material-ui/core';
import Branches from './Branches';
import WhatsappPhone from './WhatsappPhone';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';
import i18n from '../common/i18n';
import NextSteps from './NextSteps';

const ApprovedRequest = ({ commerceInfo, offerData, commerceList }) => {
  const [whatsappPhone, setWhatsappPhone] = useState();
  const [isMoto, setIsMoto] = useState();
  const [isElectro, setIsElectro] = useState();

  const init = async () => {
    const whatsappPhoneResult = commerceInfo.phoneNumbers.find((phone) => phone.type === 'WHATSAPP');
    setWhatsappPhone(whatsappPhoneResult);
    const pM = commerceInfo.products.find((product) => product.sappIdProducto === 2 || product.sappIdProducto === 36);
    setIsMoto(pM !== undefined);
    setIsElectro(pM === undefined);
  };

  useEffect(() => init(), []);

  return (
    <>
      <Box mt={10} />
      <Grid container>
        <Grid item xs={1} md={2} />
        <Grid item xs={9} md={9}>
          <Grid container align="left" spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h1" color="primary">{i18n.offerApprovedRequestTitle}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {isElectro
                ? (
                  <>
                    <Typography variant="h4" className="subtitle">
                      {i18n.offerElectroApprovedRequestOfferMessage}
                      <strong>{moneyFormatterWithoutDecimals.format(offerData.maxAmount)}</strong>
                      {' '}
                      {i18n.offerElectroApprovedRequestOfferMessage2}
                    </Typography>
                    <Typography variant="h4" className="installments">
                      <strong>
                        {fillMessageWith(i18n.offerElectroApprovedRequestInstallmentMessage1,
                          [commerceInfo.businessName])}
                      </strong>
                      {i18n.offerElectroApprovedRequestInstallmentMessage2}
                    </Typography>

                  </>
                )
                : (
                  <>
                    <Typography variant="h4" className="subtitle">
                      {i18n.offerApprovedRequestOfferMessage}
                      <strong>{moneyFormatterWithoutDecimals.format(offerData.maxAmount)}</strong>
                    </Typography>
                    <Typography variant="h4" className="installments">
                      {`${i18n.offerApprovedRequestInstallmentMessage1} ${offerData.installments[0].installments} 
                      ${i18n.offerApprovedRequestInstallmentMessage2} 
                      ${moneyFormatterWithoutDecimals.format(offerData.installments[0].amount)}`}
                    </Typography>
                  </>
                )}
            </Grid>
          </Grid>
          <Box mt={10} />
          {/* PASOS PARA OBTENER EL PRODUCTO */}
          <NextSteps isMoto={isMoto} installments={offerData.installments[0].installments} />
          <Box mt={10} />
          {/* Mensaje recordatorio */}
          <Typography>
            {i18n.offerApprovedRequestReminderMessage}
          </Typography>
          <Box mt={10} />
          {/* LISTADO DE SUCURSALES */}
          <Branches commerceList={commerceList} />
          <Box mt={10} />
          {/* CONTACTO WHATSAPP */}
          {whatsappPhone && (
            <WhatsappPhone number={whatsappPhone.phoneNumber} />
          )}
          <Box mb={5} />
        </Grid>
      </Grid>
    </>
  );
};

ApprovedRequest.propTypes = {
  commerceInfo: PropTypes.shape({
    businessName: PropTypes.string.isRequired,
    phoneNumbers: PropTypes.arrayOf.isRequired,
    products: PropTypes.arrayOf.isRequired,
  }).isRequired,
  offerData: PropTypes.shape({
    maxAmount: PropTypes.number.isRequired,
    installments: PropTypes.arrayOf.isRequired,
  }).isRequired,
  commerceList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ApprovedRequest;
