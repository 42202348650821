import { Box, Grid, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getShowcaseInformation } from '../api/showcaseService';
import NavBar from '../components/NavBar';
import HowTo from '../components/HowTo';
import Banner from '../components/Banner';
import StartOnboarding from '../components/StartOnboarding';
import Footer from '../components/Footer';
import Loading from '../components/common/Loading';
import { useUserData } from '../contexts/userDataContext';
import { routes } from '../constants/routes';
import Toast from '../components/common/Toast';
import i18n from '../common/i18n';
import { isShowcaseBranch } from '../utils/functionsUtil';
import StartOnboardingBranch from '../components/StartOnboardingBranch';

const Home = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [showcaseInfo, setShowcaseInfo] = useState();
  const { setUserData } = useUserData();
  const [errorMessage, setErrorMessage] = useState(undefined);

  const queryParams = useLocation().search;

  const history = useHistory();

  const init = async () => {
    try {
      const showcaseId = window.location.hostname.split('.')[0];
      const res = await getShowcaseInformation(showcaseId);
      setShowcaseInfo(res.data);
    } catch (error) {
      setErrorMessage(i18n.homeGetShowcaseInfoErrorMessage);
    } finally {
      setInitLoading(false);
    }
  };

  const handleSubmit = (data) => {
    setUserData(data);
    history.push({
      pathname: routes.onboarding,
      search: queryParams,
    });
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => init(), []);

  return (
    <>
      { showcaseInfo && !showcaseInfo.enabled ? (
        <Box mt={20} marginLeft={3} marginRight={3}>
          <Typography variant="h2">
            {i18n.homeShowcaseDisable}
          </Typography>
        </Box>
      ) : (
        <>
          <NavBar />
          {!initLoading && showcaseInfo && (
            <>
              <Banner showcaseInfo={showcaseInfo} />
              <Grid container align="left">
                {isShowcaseBranch(showcaseInfo) ? (
                  <Grid item xs={12} md={12} style={{ backgroundColor: '#f5f5f5' }}>
                    <StartOnboardingBranch
                      onSubmit={handleSubmit}
                      loading={false}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <HowTo
                        showcaseBusinessName={showcaseInfo.businessName}
                        showcaseProducts={showcaseInfo.products}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StartOnboarding
                        onSubmit={handleSubmit}
                        loading={false}
                        isHome
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Footer />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={dismissMessage}
        />

      )}
      {initLoading && (
        <Loading />
      )}
    </>
  );
};

export default Home;
