import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import NavBar from '../components/NavBar';
import {
  getShowcaseInformation, getOnboardingOffer, sendOfferToCustomer, getCommerceListByHeadOffice,
} from '../api/showcaseService';
import Loading from '../components/common/Loading';
import RejectedRequest from '../components/RejectedRequest';
import ApprovedRequest from '../components/ApprovedRequest';
import Toast from '../components/common/Toast';
import i18n from '../common/i18n';
import { isShowcaseBranch } from '../utils/functionsUtil';
import ApprovedRequestBranch from '../components/ApprovedRequestBranch';

const Offer = () => {
  const urlParams = new URLSearchParams(useLocation().search);
  const { hash } = useParams();
  const [initLoading, setInitLoading] = useState(true);
  const [commerceInfo, setCommerceInfo] = useState();
  const [offerData, setOfferData] = useState();
  const [commerceList, setCommerceList] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const sendOfferToCustomerBySms = async (commerceData) => {
    try {
      await sendOfferToCustomer(commerceData.showcaseId, hash);
    } catch (error) {
      console.log('Error al enviar sms');
    }
  };

  const getCommerceList = async (commerceData) => {
    try {
      const res = await getCommerceListByHeadOffice(commerceData.headOfficeId);
      setCommerceList(res.data);
    } catch (error) {
      setErrorMessage(i18n.offerLoadError);
    }
  };

  const getOffer = async (commerceData) => {
    try {
      const res = await getOnboardingOffer(hash);
      setOfferData(res.data);
      if (res.data.approved && res.data.amount > 0 && !urlParams.has('sms')) {
        sendOfferToCustomerBySms(commerceData);
      }

      await getCommerceList(commerceData);
    } catch (error) {
      setErrorMessage(i18n.offerLoadError);
    }
  };

  const init = async () => {
    try {
      setInitLoading(true);
      const showcaseId = window.location.hostname.split('.')[0];
      const res = await getShowcaseInformation(showcaseId);

      const commerceInfoResult = res.data;
      setCommerceInfo(commerceInfoResult);

      await getOffer(commerceInfoResult);
    } catch (error) {
      setErrorMessage(i18n.offerLoadError);
    } finally {
      setInitLoading(false);
    }
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => init(), []);

  return (
    <>
      <NavBar />
      {/* SOLICITUD RECHAZADA */}
      {!initLoading && offerData && !offerData.approved && (
        <RejectedRequest offerData={offerData} />
      )}
      {/* SOLICITUD APROBADA */}
      {!initLoading && offerData && offerData.approved && commerceList && !isShowcaseBranch(commerceInfo) && (
        <ApprovedRequest commerceInfo={commerceInfo} offerData={offerData} commerceList={commerceList} />
      )}
      {!initLoading && offerData && offerData.approved && commerceList && isShowcaseBranch(commerceInfo) && (
        <ApprovedRequestBranch offerData={offerData} />
      )}
      {initLoading && (
        <Loading />
      )}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={dismissMessage}
        />
      )}
    </>
  );
};

export default Offer;
