import * as yup from 'yup';
import i18n from '../../common/i18n';

/* eslint import/prefer-default-export: off */
export const dniSchemaValidation = yup.object().shape({
  dni: yup.string().matches(/^[0-9]{6,9}$/,
    { excludeEmptyString: true, message: i18n.dniSchemaValidationDNILength })
    .required(i18n.dniSchemaValidationDNIRequired),
});

export const cellphonechemaValidation = yup.object().shape({
  cellphone: yup.string().matches(/^[0-9]+$/, i18n.cellphoneInputCellphoneInputHint)
    .required(i18n.cellphoneInputCellphoneInputHint),
});

export const verificationCodeSchemaValidation = yup.object().shape({
  code: yup.string().matches(/^[0-9]{4,4}$/,
    { excludeEmptyString: true, message: i18n.verificationCodeSchameValidationCodeNumber4Digits })
    .required(i18n.verificationCodeSchameValidationCodeRequired),
});
