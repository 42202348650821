import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, Typography,
} from '@material-ui/core';
import { useParams } from 'react-router';
import i18n from '../common/i18n';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import CustomCard from './common/CustomCard';
import branchDeskImg from '../assets/imgs/sucu_desk.svg';
import branchSelfieImg from '../assets/imgs/sucu_selfie.svg';
import { ORIGINATION_URL, getEnv } from '../api/env';
import Toast from './common/Toast';
import { selfManagement } from '../api/showcaseService';
import Loading from './common/Loading';

const ApprovedRequestBranch = ({ offerData }) => {
  const { hash } = useParams();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const handleSelfManagement = async () => {
    try {
      setLoading(true);
      await selfManagement(hash);
      console.log(`${getEnv(ORIGINATION_URL)}/m/${hash}`);
      window.location.href = `${getEnv(ORIGINATION_URL)}/m/${hash}`;
    } catch (error) {
      setErrorMessage(i18n.offerBranchSelfmanagedError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <>
          <Box mt={10} />
          <Grid container>
            <Grid item xs={1} md={2} />
            <Grid item xs={9} md={9}>
              <Grid container align="left" spacing={2}>
                <Grid item xs={12} md={12} style={{ marginBottom: '50px' }}>
                  <Typography variant="h1" color="primary">{i18n.offerApprovedRequestTitle}</Typography>
                  <Box mt={2} />
                  <Typography variant="h4" className="subtitle">
                    {i18n.offerBranchDescription[0]}
                    <strong>
                      {` ${moneyFormatterWithoutDecimals.format(offerData.maxAmount)} `}
                    </strong>
                    {i18n.offerBranchDescription[1]}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomCard
                    img={branchSelfieImg}
                    title={i18n.offerBranchSelfieTitle}
                    description={i18n.offerBranchSelfieDescription}
                    onClick={handleSelfManagement}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomCard
                    img={branchDeskImg}
                    title={i18n.offerBranchDeskTitle}
                    description={i18n.offerBranchDeskDescription}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={() => setErrorMessage(undefined)}
        />
      )}
      {loading && (
        <Loading />
      )}
    </>
  );
};

ApprovedRequestBranch.propTypes = {
  offerData: PropTypes.shape().isRequired,
};

export default ApprovedRequestBranch;
