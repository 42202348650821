import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import NavBar from '../components/NavBar';
import Loading from '../components/common/Loading';
import { getHashLoanByMinifyIdWithUrlParams } from '../api/showcaseService';
import { ORIGINATION_URL, getEnv } from '../api/env';
import { redirectStep } from '../utils/functionsUtil';
import { routes } from '../constants/routes';

const RedirectStep = () => {
  const [initLoading] = useState(true);
  const { hash } = useParams();
  const history = useHistory();
  const originationUrl = getEnv(ORIGINATION_URL);

  const redirectToAbsoluteUrl = (url, params) => {
    let redirectUrl = url;
    if (params) {
      redirectUrl += `?${params}`;
    }
    window.location.href = redirectUrl;
  };

  const doRedirect = (loanRequest) => {
    const step = redirectStep(loanRequest);
    if (step === routes.offerSelection) {
      // Debe dirigir internamente a react
      history.push(`${routes.offerSelection}/${hash}`);
    } else {
      // Debe dirigir a originacion
      window.location.href = `${originationUrl}/${step}/${hash}`;
    }
  };

  const init = async () => {
    try {
      const response = await getHashLoanByMinifyIdWithUrlParams(hash);
      if (response.data) {
        if (response.data.url) {
          redirectToAbsoluteUrl(response.data.url, response.data.urlParams);
        }

        const loanRequest = response.data.loanRequest || response.data;
        doRedirect(loanRequest);
      } else {
        window.location.href = `${originationUrl}/firstStep`;
      }
    } catch (error) {
      window.location.href = `${originationUrl}/firstStep`;
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      <NavBar />
      {initLoading && (
        <Loading />
      )}
    </>
  );
};

export default RedirectStep;
