import { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import {
  Box, Button, Grid, Typography, RadioGroup,
} from '@material-ui/core';
import CardInstallment from './CardInstallment';
import BenefitFirstIntallment from './BenefitFirstIntallment';
import i18n from '../common/i18n';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { setInstallment, getLoanRequest } from '../api/showcaseService';
import { redirectStep } from '../utils/functionsUtil';
import { routes } from '../constants/routes';
import { ORIGINATION_URL, getEnv } from '../api/env';

/* eslint arrow-body-style:off */
const LoanInstallments = ({
  businessName, requestedAmount, installmentsData, installmentsDataOriginalLine, hasBenefitFirstIntallment,
}) => {
  const history = useHistory();
  const { hash } = useParams();
  const originationUrl = getEnv(ORIGINATION_URL);
  const [selectedInstallment, setSelectedInstallment] = useState(installmentsData[0]);

  const onClickInstallment = (installments) => {
    // buscar el installment y setearlo como seleccionado
    setSelectedInstallment(installmentsData.find((installment) => installment.installments === installments));
  };

  const getOriginalInstallmentAmount = (installments) => {
    let value;

    if (installmentsDataOriginalLine && installmentsDataOriginalLine.length > 0) {
      const instOrigLine = installmentsDataOriginalLine.find((inst) => inst.installments === installments);
      if (instOrigLine) {
        value = instOrigLine.amount;
      }
    }

    return value;
  };

  const confirmInstallment = async () => {
    try {
      await setInstallment(hash, selectedInstallment, requestedAmount);
      const resLoanRequest = await getLoanRequest(hash);
      const step = redirectStep(resLoanRequest);
      if (step === routes.offerSelection) {
        // Debe dirigir internamente a react
        history.push(`${routes.offerSelection}/${hash}`);
      } else {
        // Debe dirigir a originacion
        window.location.href = `${originationUrl}/${step}/${hash}`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box mt={3} />
      <Grid container justify="center">
        <Grid item xs={10} md={10}>
          <Typography variant="h3" className="h2-subtitle">
            {businessName}
            {' - '}
            {i18n.loanInstallmentsTitle}
          </Typography>
          <Typography variant="h3" className="h2-subtitle">
            {i18n.loanInstallmentsSubTitle}
            {' '}
            <strong>{moneyFormatterWithoutDecimals.format(requestedAmount)}</strong>
          </Typography>
          <Box mt={3} />
          {hasBenefitFirstIntallment && (
            <BenefitFirstIntallment selectedInstallment={selectedInstallment} />
          )}
          <Box mt={3} />
          <RadioGroup
            name="optionToSelect"
            value={selectedInstallment}
            onChange={() => { }}
          >
            {installmentsData && installmentsData.map((installment) => (
              <Grid
                item
                key={installment.installments}
              >
                <CardInstallment
                  installment={installment}
                  isSelected={selectedInstallment.installments === installment.installments}
                  onClickInstallment={() => onClickInstallment(installment.installments)}
                  originalInstallmentAmount={getOriginalInstallmentAmount(installment.installments)}
                />
              </Grid>
            ))}
          </RadioGroup>
          <Box mt={3} />
          <Button onClick={confirmInstallment} variant="contained" color="primary" fullWidth>
            {i18n.loanInstallmentsContinue}
          </Button>
          <Box mt={3} />
        </Grid>
      </Grid>
    </>
  );
};

LoanInstallments.propTypes = {
  requestedAmount: PropTypes.number.isRequired,
  installmentsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  installmentsDataOriginalLine: PropTypes.arrayOf(PropTypes.shape({})),
  hasBenefitFirstIntallment: PropTypes.bool.isRequired,
  businessName: PropTypes.string,
};
LoanInstallments.defaultProps = {
  installmentsDataOriginalLine: [],
  businessName: '',
};

export default LoanInstallments;
