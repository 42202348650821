import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import imgWhatsapp from '../assets/imgs/whatsappContact.svg';

const WhatsappPhone = ({ number }) => {
  const openWhatsapp = () => {
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=Hola, me gustaría averiguar por el préstamo que tengo pre aprobado con Credicuotas`, '_blanck');
  };

  return (
    <>
      <Button onClick={openWhatsapp}>
        <img className="whatsapp-image" src={imgWhatsapp} alt="WS" />
      </Button>
    </>
  );
};

WhatsappPhone.propTypes = {
  number: PropTypes.string.isRequired,
};

export default WhatsappPhone;
