import PropTypes from 'prop-types';
import {
  Card, CardContent, Grid, makeStyles, Typography, CardActionArea, Radio,
} from '@material-ui/core';
import bgRibbon from '../assets/imgs/bg_ribbon.svg';
import { moneyFormatter, moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';
import i18n from '../common/i18n';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '10px',
  },
  cardOutlined: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '10px',
    borderColor: theme.palette.primary.main,
  },
  cuota: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '16px',
  },
  originalLineText: {
    fontFamily: 'Open Sans, sans-serif',
    marginLeft: '45px',
    fontSize: '0.8rem',
    fontWeight: 700,
  },
  originalLineInterestsAndTaxes: {
    fontFamily: 'Open Sans, sans-serif',
    marginLeft: '45px',
    fontSize: '11px',
    fontWeight: 400,
  },
  originalLineAmount: {
    textDecoration: 'line-through',
    color: 'grey',
  },
  originalLineDiscount: {
    fontSize: '1.1rem',
  },
  pink: {
    color: '#F2025D',
    fontSize: '10px',
  },
  // TODO: RIBBON
  containerRibbon: {
    position: 'relative',
  },
  promoRibbon: {
    backgroundImage: bgRibbon,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'white',
    padding: '10px 2px',
    height: '50px',
    width: '40px',
  },
  positionRibbon: {
    position: 'absolute',
    top: '0px',
    left: '260px',
  },
  textRibbon: {
    fontSize: '1rem',
    lineHeight: '15px',
  },
  separator: {
    width: '100%',
    margin: '5px 15px',
    borderTop: '0px solid lightgray',
  },
}));

/* eslint arrow-body-style:off */
const CardInstallment = ({
  installment, onClickInstallment, isSelected, originalInstallmentAmount,
}) => {
  const classes = useStyles();

  return (
    <>
      <Card className={isSelected ? classes.cardOutlined : classes.card} variant="outlined">
        <CardActionArea
          disableRipple
          onClick={onClickInstallment}
        >
          <CardContent>
            <Grid container style={{ color: '#4a4a4a' }}>
              <Grid item>
                {/* TODO: RIBBON */}
                {originalInstallmentAmount && (
                  <>
                    <Typography className={classes.textRibbon} style={{ textAlign: 'right', borderColor: 'white' }}>
                      {'Menos '}
                      {(((originalInstallmentAmount - installment.amount) * 100)
                        / originalInstallmentAmount).toFixed(0)}
                      %
                    </Typography>
                  </>
                )}

                <span className={classes.cuota}>

                  <Radio
                    checked={isSelected}
                    value={installment.installments}
                    name="radio-button-demo"
                    color="primary"
                    size="small"
                  />

                  {fillMessageWith(i18n.cardInstallmentsPlan, [installment.installments])}
                  <strong>{moneyFormatterWithoutDecimals.format(installment.amount).replace(/^\D+/, '$')}</strong>

                </span>

                {originalInstallmentAmount && (
                  <>
                    <Typography item className={classes.originalLineText}>
                      <span className={classes.originalLineAmount}>
                        {moneyFormatterWithoutDecimals.format(originalInstallmentAmount).replace(/^\D+/, '$')}
                      </span>
                      <br />
                      <span className={classes.pink}>
                        {'Ahorrá '}
                        <span className={classes.originalLineDiscount}>
                          { moneyFormatterWithoutDecimals.format(originalInstallmentAmount - installment.amount)
                            .replace(/^\D+/, '$') }
                        </span>
                      </span>
                    </Typography>
                  </>
                )}
              </Grid>

              {isSelected && (
                <>
                  <hr className={classes.separator} />
                  <Grid item>
                    <Grid item>
                      <Typography className={classes.originalLineInterestsAndTaxes}>
                        {i18n.cardInstallmentsInterests}
                        {moneyFormatter.format(installment.interests).replace(/^\D+/, '$')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.originalLineInterestsAndTaxes}>
                        {i18n.cardInstallmentsTax}
                        {moneyFormatter.format(installment.tax).replace(/^\D+/, '$')}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

CardInstallment.propTypes = {
  installment: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    discount: PropTypes.number,
    estimatedAnnualRate: PropTypes.number,
    firstInstallmentAmountWithDiscount: PropTypes.number,
    firstInstallmentDiscountPercentage: PropTypes.number,
    idLinea: PropTypes.number,
    installments: PropTypes.number.isRequired,
    interests: PropTypes.number,
    minimumAmount: PropTypes.number,
    nominalAnnualRate: PropTypes.number,
    originalAmount: PropTypes.number,
    serviceCost: PropTypes.number,
    tax: PropTypes.number.isRequired,
    totalFinancialCost: PropTypes.number,
  }).isRequired,
  originalInstallmentAmount: PropTypes.number,
  onClickInstallment: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
CardInstallment.defaultProps = {
  originalInstallmentAmount: undefined,
};

export default CardInstallment;
