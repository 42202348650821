import { Box } from '@material-ui/core';
import i18n from '../common/i18n';
import '../assets/css/footer.css';

/* eslint arrow-body-style:off */
const Footer = () => {
  return (
    <>
      <Box m={3} />
      <Box
        component="div"
        align="center"
        className="footer-cc"
      >
        <Box m={3} />
        <Box component="span">
          {i18n.footerDescription1}
          <br />
          {i18n.footerDescription2}
        </Box>
        <Box m={5} />
        <Box component="div" maxWidth={400}>
          <img src="https://s3-sa-east-1.amazonaws.com/showcase-assets/mobile_footer_new.png" alt="Grupo Bind" width="100%" />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
