import Axios from 'axios';
import { ORIGINATION_SELFIE_API, getEnv } from './env';

const urlBackend = getEnv(ORIGINATION_SELFIE_API);

export const instance = Axios.create({
  timeout: 50000,
  baseURL: `${urlBackend}`,
  withCredentials: true,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
});

export const fetch = (config) => instance.request(config)
  .then((res) => res)
  .catch((err) => {
    throw err;
  });

export default fetch;
