import { routes } from '../constants/routes';

const fillRegexp = /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g;

/* eslint import/prefer-default-export:off */
export const fillMessageWith = (message, ...args) => {
  const values = args.reduce((acc, v, i) => {
    acc[i.toString()] = v;
    return acc;
  }, {});
  return message.replace(fillRegexp, (exp, prop) => (values[prop] !== undefined ? values[prop] : ''));
};

export const isShowcaseBranch = (showcaseInfo) =>
  showcaseInfo.products[0].sappIdProducto === 1 && showcaseInfo.products[0].sappIdOrganismo === 8;

export const redirectStep = (loanRequest) => {
  // En caso de que no sea el paso de offerSelection (react) lo enviamos al '/m' de originacion
  // y sea este quien lo envie al paso correspondiente
  if (!loanRequest.isApproved) {
    return 'm';
  }
  if (!loanRequest.installmentAmountSelected) {
    // seleccion plan de cuotas en react mismo
    return routes.offerSelection;
  }
  return 'm';
};

export const isMobileOperatingSystem = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.window.opera;
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};
