import { Box, Grid, Typography } from '@material-ui/core';
import i18n from '../common/i18n';
import warningImg from '../assets/imgs/warning.svg';

const MaintenanceMessage = () => (
  <>
    <Box mt={5} />
    <Grid container>
      <Grid item xs={12} md={12}>
        <img src={warningImg} alt="calculando" width="150px" />
        <Box mt={3} />
        <Typography variant="h2">{i18n.maintenanceMessage}</Typography>
        <Box mt={2} />
        <Typography variant="h5">{i18n.maintenanceMessageTryLater}</Typography>
      </Grid>
    </Grid>
  </>
);

export default MaintenanceMessage;
