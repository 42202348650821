import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import i18n from '../common/i18n';
import icon from '../assets/imgs/icono-vidriera.png';

/* eslint arrow-body-style:off */
const RejectedRequest = ({ offerData }) => {
  return (
    <>
      <Box mt={10} />
      <Grid container justify="center">
        <Grid item xs={10} md={10}>
          <Typography variant="h3">
            <strong className="capitalize">{offerData.fullname.toLowerCase()}</strong>
            {i18n.offerRejectedRequestMessage}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={10} />
      <Grid container justify="center">
        <Grid item xs={10} md={10}>
          <Box className="rejected-description">
            <img src={icon} alt="logo" className="img-vidrieras" />
            <Typography variant="h3">
              {`${i18n.offerRejectedRequestMessageDescription[0]} `}
              <strong>{`${i18n.offerRejectedRequestMessageDescription[1]} `}</strong>
              {i18n.offerRejectedRequestMessageDescription[2]}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

RejectedRequest.propTypes = {
  offerData: PropTypes.shape({
    fullname: PropTypes.string.isRequired,
  }).isRequired,
};

export default RejectedRequest;
