import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import i18n from '../common/i18n';

/* eslint arrow-body-style:off */
const SelectedUser = ({ user, goToBack }) => {
  return (
    <>
      <Box component="div">
        <Typography variant="h3" className="h2-subtitle">
          <Box component="span" className="identity-ok">
            <CheckCircle />
          </Box>
          {i18n.cellphoneInputDNIValidated}
        </Typography>
      </Box>
      <Typography className="selected-user">{`${user.nombrecompleto.toLowerCase()} (${user.cuit})`}</Typography>
      <Button onClick={goToBack}>
        {i18n.cellphoneInputChangeUser}
      </Button>
    </>
  );
};

SelectedUser.propTypes = {
  user: PropTypes.shape({
    nombrecompleto: PropTypes.string.isRequired,
    cuit: PropTypes.string.isRequired,
  }).isRequired,
  goToBack: PropTypes.func.isRequired,
};

export default SelectedUser;
