import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { dniSchemaValidation } from '../forms/schemaValidations/schemaValidations';
import i18n from '../common/i18n';
import TextInput from './common/TextInput';
import LoadingButton from './common/LoadingButton';

const StartOnboarding = ({ onSubmit, loading, isHome }) => {
  const methods = useForm({
    resolver: yupResolver(dniSchemaValidation),
    mode: 'onChange',
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box component="div" className={`vidrieras ${isHome ? 'onboardingdni' : ''}`}>
            <Box component="div" className="paddingVidrieras">
              <Box m={3} />
              <Typography variant="h2" className="vidrieras">{i18n.startOnboardingTitle}</Typography>
              <Typography variant="h3" className="vidrieras">{i18n.startOnboardingSubtitle}</Typography>

              <TextInput
                variant="standard"
                name="dni"
                label={i18n.startOnboardingSubtitle}
                fullWidth
                helperText={i18n.startOnboardingInputDNIHelperText}
                inputProps={{ maxLength: 9 }}
                mandatory
              />
              <Box m={5} />
              <Box>
                <LoadingButton
                  color="primary"
                  type="submit"
                  style={{ width: '100%' }}
                  loading={loading}
                  disabled={!methods.formState.isValid}
                >
                  {i18n.startOnboardingButtonInitRequest}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

StartOnboarding.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isHome: PropTypes.bool,
};

StartOnboarding.defaultProps = {
  isHome: false,
};

export default StartOnboarding;
