import './App.css';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

import Theme from './assets/theme/theme';
import AppRouter from './navigation/AppRouter';

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <AppRouter />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
