import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import i18n from '../common/i18n';

/* eslint arrow-body-style:off */
const RejectedLoan = ({ loanRequest }) => {
  return (
    <>
      <Box mt={10} />
      <Grid container justify="center">
        <Grid item xs={10} md={10}>
          <Typography variant="h3">
            <strong className="capitalize">{loanRequest.customerName.toLowerCase()}</strong>
            {i18n.offerRejectedLoanMessage}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

RejectedLoan.propTypes = {
  loanRequest: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
  }).isRequired,
};

export default RejectedLoan;
