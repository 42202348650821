import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { routes } from '../constants/routes';
import { UserDataProvider } from '../contexts/userDataContext';
import Home from '../pages/home';
import Offer from '../pages/offer';
import OfferSelection from '../pages/offerSelection';
import Onboarding from '../pages/onboarding';
import { GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID, getEnv } from '../api/env';
import RedirectStep from '../pages/redirectStep';

/* eslint arrow-body-style:off */
const AppRouter = () => {
  useEffect(() => {
    ReactGA.initialize(getEnv(GOOGLE_ANALYTICS_ID));
    TagManager.initialize({ gtmId: getEnv(GOOGLE_TAG_MANAGER_ID) });
  }, []);

  return (
    <>

      <BrowserRouter>
        <HelmetProvider>
          <Helmet>
            <title>{window.location.hostname}</title>
            <meta name="robots" content="noindex" />
          </Helmet>
          <UserDataProvider>
            <Switch>
              <Route exact path={routes.home}>
                <Home />
              </Route>
              <Route exact path={routes.onboarding}>
                <Onboarding />
              </Route>
              <Route path={`${routes.offer}/:hash`}>
                <Offer />
              </Route>
              <Route path={`${routes.offerSelection}/:hash`}>
                <OfferSelection />
              </Route>
              <Route path={`${routes.redirectStep}/:hash`}>
                <RedirectStep />
              </Route>
              <Redirect to={routes.home} />
            </Switch>
          </UserDataProvider>
        </HelmetProvider>
      </BrowserRouter>
    </>
  );
};
export default AppRouter;
